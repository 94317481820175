export class PaymentGatewayProvider {
  id: number;
  name: string;
  fields: string[];

  /** 
   * @param json An object to create and PaymentGatewayProvider
   */
  constructor(json: any) {
    this.id = json.id ? json.id : null;
    this.name = json.name ? json.name : null;
    this.fields = json.fields && json.fields.length > 0 ? json.fields : [];
  }
}