import { Injectable } from '@angular/core';

import { CrudService } from '../../core/services';
import { ProductLayout } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ProductLayoutService extends CrudService<ProductLayout> {

  createResource(params: any): ProductLayout {
    return new ProductLayout(params);
  }

  resourceName({plural}: {plural?: boolean} = {}): string {
    return plural ? 'product_layouts' : 'product_layout';
  }

}
