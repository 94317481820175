import { Injectable } from '@angular/core';

import { CrudService } from '../../core/services';
import { Client } from '../models';

@Injectable()
export class ClientService extends CrudService<Client> {

  createResource(params: any): Client {
    return new Client(params);
  }

  resourceName({plural}: {plural?: boolean} = {}): string {
    return plural ? 'clients' : 'client';
  }
}
