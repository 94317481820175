import { Injectable } from '@angular/core';

import { CrudService } from '../../core/services';
import { Article } from '../models';

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends CrudService<Article> {

  createResource(params: any): Article {
    return params as Article;
  }

  resourceName({plural}: {plural?: boolean} = {}): string {
    return plural ? 'articles' : 'article';
  }

}
