import { Injectable } from '@angular/core';

import { CrudService } from '../../core/services';
import { Category } from '../models';

@Injectable()
export class CategoryService extends CrudService<Category> {

  createResource(params: any): Category {
    return new Category(params);
  }

  resourceName({plural}: {plural?: boolean} = {}): string {
    return plural ? 'categories' : 'category';
  }
}
