import { ProductLayoutElement, transformAndGroupByType } from './product-layout-element';

export class ProductLayoutTab {
  id: number;
  name: string;
  order: number;
  media: ProductLayoutElement[] = [];
  dots: ProductLayoutElement[] = [];
  texts: ProductLayoutElement[] = [];

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.order = json.order;

    const elements = json.elements || [];

    [ this.media, this.dots, this.texts ] = transformAndGroupByType(elements);
  }
}
