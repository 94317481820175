type ElementType = 'medium'|'dot'|'text';

export class ProductLayoutElement {
  id: number;
  elementType: ElementType;
  coordX: string;
  coordY: string;
  hint: string;
  width: string;

  constructor(json: any) {
    this.id = json.id;
    this.elementType = json.element_type;
    this.coordX = json.coord_x;
    this.coordY = json.coord_y;
    this.hint = json.hint;
    this.width = json.width;
  }
}

const transformElements = (elements: any[], type: ElementType) => {
  return elements.
    filter(e => e.element_type === type).
    map(e => new ProductLayoutElement(e));
};

export const transformAndGroupByType = (elements: any[]) => {
  return [
    transformElements(elements, 'medium'),
    transformElements(elements, 'dot'),
    transformElements(elements, 'text')
  ];
};
