import { Injectable } from '@angular/core';
import { CrudService } from 'src/app/core/services';
import { environment } from 'src/environments/environment';
import { PaymentGatewayProvider } from '../models/payment-gateway-providers';

@Injectable()
export class PaymentGatewayProviderService extends CrudService<PaymentGatewayProvider> {

  createResource(params: any): PaymentGatewayProvider {
    return new PaymentGatewayProvider(params);
  }

  resourceName({plural}: {plural?: boolean} = {}): string {
    return plural ? 'payment_gateway_providers' : 'payment_gateway_provider';
  }

  resourcePath({ parentId }: { parentId?: number } = {}): string {
    return `${this.resourceName({ plural: true })}`;
  }
}
