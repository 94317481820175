import { ViewChild } from "@angular/core";

import { Pagination } from "../models";
import { CrudService } from "../services";
import { SidebarService } from "../../core/services";
import { FilterValue } from "../../shared";
import { FormControl, FormGroup, Validators } from "@angular/forms";

export class CrudListComponent<T> {
  @ViewChild("tableTmpl", { static: false }) table: any;

  parentId: number;
  loading = false;
  overrideResourceName: string = null;
  rows: T[] = [];
  columns = [];
  page: Pagination = new Pagination();
  sort = { direction: "desc", prop: "id" };

  private applyedFilters: FilterValue[] = [];
  initialFilters: FilterValue[] = [];
  searchForm: FormGroup;

  constructor(private resourceSrv: CrudService<T>, sidebarSrv: SidebarService) {
    this.searchForm = new FormGroup({
      search: new FormControl("", []),
    });
    sidebarSrv.onChanges().subscribe((state) => {
      if (this.table) {
        setTimeout(() => this.table.recalculate(), 150);
      }
    });
  }

  protected loadResources(page?: number, filters?: any) {
    this.loading = true;
    console.log("loading resources");
    const params = {
      page: page ? page : this.page.currentPage,
      pageSize: this.page.pageSize,
      sort: this.sort,
      filters: filters,
    };
    this.resourceSrv.all(params, this.pathOptions).subscribe(
      (data) => {
        console.log("data from  load", data);
        this.rows = data.resources;
        console.log(data);
        data.pagination.pageSize = this.page.pageSize;
        this.page = data.pagination;
        console.log(this.page);
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        console.error(error);
      }
    );
  }

  private get pathOptions(): { parentId: number } {
    let pathOpt = undefined;
    if (this.parentId || this.overrideResourceName) {
      pathOpt = {};
      if (this.parentId) {
        pathOpt['parentId'] = this.parentId
      }
      if (this.overrideResourceName) {
        pathOpt['overrideResourceName'] = this.overrideResourceName
      }
    }
    return pathOpt;
  }

  setPage(pageInfo) {
    this.page.currentPage = pageInfo.offset + 1;
    this.loadResources(
      this.page.currentPage,
      this.filterToParams(this.applyedFilters)
    );
  }

  onSort(event) {
    const sort = event.sorts[0];
    this.sort.direction = sort.dir;
    this.sort.prop = sort.prop;
    this.loadResources(1, this.filterToParams(this.applyedFilters));
  }

  filterToParams(filterValues: FilterValue[]) {
    const filterParams = {};

    filterValues.forEach((fValue) => {
      filterParams[fValue.filter.id] = fValue.value;
    });

    return filterParams;
  }

  onFilterChange(filters: FilterValue[]) {
    this.applyedFilters = filters;
    this.loadResources(1, this.filterToParams(filters));
  }

  applyFilter(filter: FilterValue) {
    this.applyedFilters = [...this.applyedFilters, filter];
    this.loadResources(1, this.filterToParams(this.applyedFilters));
  }

  /**
   * This method set the filter and triggers loadResources
   * @param especifiedFilter is used to especific a query param to filter in the http request
   * @returns
   */

  search(especifiedFilter? : {id:string, label:string}) {
    if (this.searchForm.invalid) {
      return false;
    }
    const search = this.searchForm.get("search").value;
    if (search && search !== "") {
      if(especifiedFilter){
        this.applyFilter(new FilterValue(especifiedFilter, search));
      }
      else{
        this.applyFilter(new FilterValue({ id: "q", label: "name" }, search));
      }
    } else {
      this.applyedFilters = []
      this.page.currentPage = 0
      this.loadResources();
    }
  }
}
