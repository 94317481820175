const fonts = [
  'Andale Mono=andale mono,times',
  'Arial=arial,helvetica,sans-serif',
  'Arial Black=arial black,avant garde',
  'Book Antiqua=book antiqua,palatino',
  'Comic Sans MS=comic sans ms,sans-serif',
  'Courier New=courier new,courier',
  'Georgia=georgia,palatino',
  'Helvetica=helvetica',
  'Impact=impact,chicago',
  'Symbol=symbol',
  'Tahoma=tahoma,arial,helvetica,sans-serif',
  'Terminal=terminal,monaco',
  'Times New Roman=times new roman,times',
  'Trebuchet MS=trebuchet ms,geneva',
  'Verdana=verdana,geneva',
  'Webdings=webdings',
  'Wingdings=wingdings,zapf dingbats'
];

const googleFonts = [{
  label: 'Indie Flower',
  value: 'indie flower, cursive',
  source: '//fonts.googleapis.com/css?family=Indie+Flower'
}];

export const tinymceConfig = {
  plugins: 'code',
  toolbar: 'undo | redo | fontselect fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | indent outdent',
  content_css: googleFonts.map(f => f.source),
  font_formats: fonts.concat(googleFonts.map(f => `${f.label}=${f.value}`)).sort().join(';')
};
