import { ProductLayoutTab } from './product-layout-tab';
import { ProductLayoutElement, transformAndGroupByType } from './product-layout-element';

export class ProductLayout {
  id: number;
  name: string;
  stylesheet: string;
  media: ProductLayoutElement[] = [];
  dots: ProductLayoutElement[] = [];
  texts: ProductLayoutElement[] = [];
  tabs: ProductLayoutTab[] = [];

  constructor(json: any) {
    this.id = json.id;
    this.name = json.name;
    this.stylesheet = json.stylesheet;
    this.tabs = (json.tabs || []).map(tab => new ProductLayoutTab(tab));

    const elements = json.elements || [];

    [ this.media, this.dots, this.texts ] = transformAndGroupByType(elements);
  }

  get sortedTabs() {
    return [...this.tabs].sort((a, b) => a.order - b.order);
  }

  get hasCommonElements() {
    return this.media.length > 0 || this.dots.length > 0 || this.texts.length > 0;
  }
}
