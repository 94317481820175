import { AbstractControl, ValidatorFn } from '@angular/forms';

export function urlFormatValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    // self value
    const value = control.value;
    let isValid = true;

    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    const regex = new RegExp(expression);

    if (value && !value.match(regex)) {
      isValid = false;
    }

    return isValid ? null : { 'format': true };
  };
}
