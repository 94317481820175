import { Injectable } from '@angular/core';

import { CrudService } from '../../core/services';
import { Brand } from '../models';

@Injectable()
export class BrandService extends CrudService<Brand> {

  createResource(params: any): Brand {
    return new Brand(params);
  }

  resourceName({plural}: {plural?: boolean} = {}): string {
    return plural ? 'brands' : 'brand';
  }
}
